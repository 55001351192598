.Paragraph {
    margin: 0 auto;
    max-width: 60rem;
    width: 100%;
    text-align: center;

    .Text {
        box-sizing: border-box;
    }

    &.WithImage {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        .Text {
            display: block;
            width: 100%;

            p {
                margin: 1rem 0;
                font-size: 1.2rem;
                line-height: 2rem;
                font-style: italic;
            }
        }
    
        .Image {
            margin-top: 1rem;
            display: inline-block;
            width: 20rem;
            min-width: 20rem;
            height: 20rem;
            border-radius: 50%;
            max-height: 100%;
        }
    }
}

@media (min-width: 720px) {
    .Paragraph {
        text-align: left;
    
        &.WithImage {
            align-items: center;
            flex-wrap: nowrap;
    
            .Text {
                display: inline-block;
                padding-right: 1rem;
    
                p {
                    margin: 1rem 0;
                    font-size: 1.3rem;
                    line-height: 2rem;
                }
            }
        
            .Image {
                margin-top: 0;
                width: 18rem;
                min-width: 18rem;
                height: 18rem;
            }
        }
    }
}

@media (min-width: 800px) {
    .Paragraph {
    
        &.WithImage {
    
            .Text {
                width: 40rem;
                padding-right: 2rem;
            }
        
            .Image {
                width: 20rem;
                min-width: 20rem;
                height: 20rem;
            }
        }
    }
}