@import "../../../../styles/main";

.CloseDrawer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    font-size: 2rem;
    color: #fff;
    cursor: pointer;
}