.SkillsWrapper {
    margin-bottom: 2rem;

    h2 {
        line-height: 2rem;
        margin-top: -0.3rem;
    }
}

.SlideIn {
    animation: slide-in 1s;
}

@keyframes slide-in {
    from { transform: translateX(100%); }
    to { transform: translateX(0); }
}

@media (min-width: 640px) {
    .SkillsWrapper {
        margin-bottom: 0;

        h2 {
            line-height: initial;
            margin-top: initial;
        }
    }
}