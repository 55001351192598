@import "./styles/main";

html {
  color: $theme1-text-medium;
  background-color: #fbfbfb;
}

body {
  margin: 0;
  font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html, .App {
  height: 100%;
  margin: 0;
}

.App {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

h2 {
  margin: 0.75rem 0 1rem;
  font-size: 1.7rem;
  font-weight: 500;
  line-height: 2.2rem;
}
h3 {
  font-size: 1.6rem;
  font-weight: 500;
}
h4 {
  font-size: 1.4rem;
  font-weight: 500;
}
p {
  font-size: 1.1rem;
  line-height: 1.5rem;
}

@media (min-width: 640px) {
  h2 {
    font-size: 2rem;
  }
}