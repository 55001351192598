.BookTheme {
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to bottom right, #0b0904, #615535);
    overflow: hidden;
    position: relative;

    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        background-image: url('./images/bookshelf-pattern.jpg');
        width: 100%;
        height: 100%;
        opacity: 0.1;
        animation: fade-in-background 1s;
    }

    .BookWrapper {

        .BookImage {
            position: absolute;
            display: inline-block;
            width: 17rem;
            max-width: 100%;
            margin: 0 auto;
            bottom: 0;
            left: 0;
            right: 0;
    
            img {
                width: 100%;
                z-index: 9;
                animation: fade-in-book 2.5s;
            }
        }
    }

    .BookThemeCanvas {
        animation: fade-in-canvas 5s;
    }

    @keyframes fade-in-book {
        from { opacity: 0 };
        to { opacity: 1 };
    }

    @keyframes fade-in-background {
        from { opacity: 0 };
        to { opacity: 0.1 };
    }

    @keyframes fade-in-canvas {
        from { opacity: 0 };
        to { opacity: 1 };
    }
}

@media (min-width: 320px) {
    .BookTheme {
        .BookWrapper {
            .BookImage {
                width: 20rem;
            }
        }
    }
}

@media (min-width: 480px) {
    .BookTheme {
        .BookWrapper {
            .BookImage {
                width: 30rem;
            }
        }
    }
}

@media (min-width: 640px) {
    .BookTheme {
        .BookWrapper {
            .BookImage {
                width: 36rem;
            }
        }
    }
}