@import "../../styles/main";

.Content {
    
    flex: 1 0 auto;

    &.FadingIn {
        animation: fade-in 0.5s linear;
    }
    &.FadingOut {
        animation: fade-out 5s linear;
    }

    @keyframes fade-in {
        from { opacity: 0.5 }
        to { opacity: 1 }
    }

    @keyframes fade-out {
        from { opacity: 1 }
        to { opacity: 0 }
    }
}