@import "../../../../styles/main";

.NavItem {
    display: block;
    width: 100%;
    box-sizing: border-box;
    
    a {
        display: block;
        text-decoration: none;
        height: 100%;
        width: 100%;
        box-sizing: border-box;
        padding: 1.3rem 1rem;
    }

    a.active,
    a:active{
        color: $theme1-primary4;
    }
}
@media (max-width: 719px) {
    $mobile-font-colour: #f0f0f0;

    .NavItem {
        border-bottom: 1px solid $mobile-font-colour;

        a {
            position: relative;
            font-size: 1.2rem;
            font-weight: 500;
            color: $mobile-font-colour;
        }

        a.active,
        a:active{
            color: $mobile-font-colour;
            font-weight: 800;

            &:before {
                content: "";
                position: absolute;
                display: inline-block;
                top: 0;
                bottom: 0;
                left: -1rem;
                width: 0.5rem;
                background-color: $mobile-font-colour;
            }
        }
        
        a:focus,
        a:hover {
            background-color: #fff;
            color: $theme1-primary4;
            animation: change-bg-colour 1.5s, change-text-colour 0.5s;
        }
    }

    @keyframes change-bg-colour {
        from { background-color: $theme1-primary4; }
        to { background-color: #fff; }
    }
    @keyframes change-text-colour {
        from { color: #fff }
        to { color: $theme1-primary4; }
    }
}

@media (min-width: 720px) {
    .NavItem {
        margin: 0;
        position: relative;
        display: flex;
        height: 100%;
        width: auto;
        align-items: center;
        border-bottom: none;
    
        a {
            position: static;
            display: inline-block;
            color: $theme1-primary5;
            height: auto;
        }
    
        a.active,
        a:active{
            color: $theme1-primary5;
            font-weight: 500;
        }
    }
    
    @keyframes lengthen {
        from { left: 50%; right: 50%; }
        to { left: 20%; right: 20%; }
    }
    
    .NavItem a:not(.active):hover:after {
        content: "";
        background: $theme1-primary4;
        position: absolute;
        bottom: 0;
        left: 20%;
        right: 20%;
        height: 3px;
        animation-name: lengthen;
        animation-duration: 0.5s;
    }
    
    .NavItem a.active:after {
        content: "";
        background: $theme1-primary4;
        position: absolute;
        bottom: 0;
        left: 20%;
        right: 20%;
        height: 3px;
    }

    .NavItem.CustomTheme {
        font-size: 1.3rem;
        font-weight: 500;
    }

    .NavItem.HomePageTheme,
    .NavItem.SkillsPageTheme
    {
        a {
            color: #fff;
        }
        a.active,
        a:active{
            color: #fff;
        }

        a:not(.active):hover {
            text-shadow: 0 0 20px #fafafa;

            &:after {
                height: 2px;
                background: #fff;
            }
        }
        
        a.active:after {
            height: 2px;
            background: #fff;
        }
    }

    .NavItem.JourneyPageTheme
    {
        a {
            color: #424242;
        }
        a.active,
        a:active{
            color: #424242;
        }

        a:not(.active):hover {
            text-shadow: 0 0 30px #222;

            &:after {
                height: 2px;
                background: #555;
            }
        }
        
        a.active:after {
            height: 2px;
            background: #555;
        }
    }

    .NavItem.ProjectsPageTheme
    {
        a {
            color: #fff;
            text-shadow: 0px 0px 5px #000;
        }
        a.active,
        a:active{
            color: #fff;
        }

        a:not(.active):hover {
            text-shadow: 0 0 10px #ff849a;

            &:after {
                height: 2px;
                background: #fff;
            }
        }
        
        a.active:after {
            height: 2px;
            background: #fff;
        }
    }

    .Transition40 {
        font-size: 1.3rem;
        font-weight: 500;
    }
    .Transition50 {
        font-size: 1.25rem;
        font-weight: 500;
    }
    .Transition60 {
        font-size: 1.2rem;
    }
    .Transition70 {
        font-size: 1.15rem;
    }
    .Transition80 {
        font-size: 1.1rem;
    }
    .Transition90 {
        font-size: 1.05rem;
    }

} 