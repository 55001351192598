@import "../../../styles/main";

.SideDrawer {
    position: fixed;
    top: 0;
    right: 0;
    width: 320px;
    max-width: 80%;
    height: 100%;
    box-sizing: border-box;
    background-color: $theme1-primary4;
    box-shadow: -1px 0 5px #555;
    z-index: 200;
    transition: transform 0.3s ease-out;

    .Header {
        position: relative;
        height: 3.5rem;
        box-sizing: border-box;
        background-color: $theme1-primary5;

        .CloseDrawerWrapper {
            display: inline-block;
            position: absolute;
            top: 0;
            right: 0;
            width: 3.5rem;
            height: 100%;
            text-align: center;

            &:focus,
            &:hover {
                transform: scale(1.2);
            }
        }

        .LogoWrapper {
            height: 100%;
            max-height: 3.5rem;
            text-align: center;
            box-sizing: border-box;
            padding: 0.5rem 0;

            :global(.LogoGlobalClass) {
                padding-right: 1rem;
            }
        }
    }

    .Main {
        padding: 0 1rem;
    }
    
}

@media all and (min-width: 720px) {
    .SideDrawer {
        display: none;
    }
}

.Open {
    transform: translateX(0);
}

.Close {
    transform: translateX(100%);
}