@import "../../../styles/main";

.DynamicCard {
    position: relative;
    width: 100%;
    min-width: 20rem;
    box-sizing: border-box;
    box-shadow: 0px 1px 5px 2px #aaa;

    &:after {
        content: "";
        background-image: url(../../../assets/images/backgrounds/project-card-background.png);
        background-size: cover;
        opacity: 0.5;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        position: absolute;
        z-index: -1;
    }

    .Heading {
        margin: 0 0.75rem;
        padding-top: 0.75rem;

        .TitleRow {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 0.6rem;

            .Title {
                display: inline-block;
                font-size: 1.5rem;
                font-weight: 600;
            }

            .Links {

                &.DesktopOnly {
                    display: none;
                }
            }
        }

        .Subtitle {
            display: block;
            font-size: 1.15rem;
            font-style: italic;
            font-weight: 500;
            line-height: 1.5rem;
            margin-bottom: 0.8rem;
        }

        .Links {
            &.MobileOnly {
                padding-bottom: 0.6rem;

                div {
                    padding: 0.6rem;
                }
            }
        }
    }

    .ContentWrapper {
        position: relative;
        height: 26rem;

        .Content {
            position: relative;
            width: 100%;
            height: 26rem;
    
            .ImageContent {
                height: 100%;
    
                .Image {
                    width: 100%;
                    height: 21.6rem;
                    background-position: center;
                    background-size: cover;
                    background-repeat: no-repeat;
    
                    img {
                        width: 100%;
                    }
                }
            }
    
            .DescriptionContent {
                box-sizing: border-box;
                height: 21.5rem;
                padding: 0.5rem 0.75rem;
                overflow-y: auto;
    
                p {
                    margin: -0.25rem 0 1.25rem;
                    font-size: 1rem;
                    line-height: 1.4rem;
                    text-align: justify;
                }
    
            }
    
            .TechUsedContent {
                padding: 0.5rem 0.75rem;
    
                h3 {
                    font-size: 1.1rem;
                    font-weight: 600;
                    margin-bottom: 0.5rem;
                }
            }            
    
        }

        .ContentNavigation {
            position: absolute;
            bottom: 0;
            width: 100%;
            text-align: center;
            background-color: rgba(255, 255, 255, 0.6);
            padding: 0.4rem 0;

            .ButtonGroup {
                width: 100%;
                display: inline-flex;
                flex-direction: row;
                justify-content: space-evenly;
            }
        }

        .FadeInContent {
            animation: content-fade-in 0.3s linear;
        }

        .SlideInContent {
            animation: content-slide-in 0.2s ease-in-out;
        }

        @keyframes content-slide-in {
            from { transform: translateX(100%); }
            to { transform: translateX(0%); }
        }

        @keyframes content-fade-in {
            from { opacity: 0; }
            to { opacity: 1; }
        }

    }

}

// @media (min-width: 400px) {
//     .DynamicCard {
//         .ContentWrapper {
//             height: 28rem;

//             .Content {
//                 height: 28rem;
//             }
//         }
//     }
// }

@media (min-width: 560px) {
    .DynamicCard {
        .Heading {
            padding-top: 0.6rem;

            .TitleRow {
                margin-bottom: 0.4rem;

                .Links {

                    &.DesktopOnly {
                        display: inline-flex;
                    }
                }
            }

            .Subtitle {
                line-height: 1.2rem;
                margin-bottom: 0.75rem;
            }

            .Links {
                &.MobileOnly {
                    display: none;
                }
            }
        }

        .ContentWrapper {
            height: 22rem;

            .Content {
                height: 22rem;

                .ImageContent {
                    .Image {
                        height: 100%;
                    }
                }

                .DescriptionContent {
                    height: 100%;

                    p {
                        line-height: 1.3rem;
                        text-align: left;
                    }
        
                }
            }

            .ContentNavigation {
                .ButtonGroup {
                    width: 65%;
                    justify-content: space-around;
                }
            }
        }
    }
}

@media (min-width: 880px) {
    .DynamicCard {
        .ContentWrapper {
            height: 24rem;

            .Content {
                height: 24rem;
            }
        }
    }
}

@media (min-width: 960px) {
    .DynamicCard {
        .ContentWrapper {
            height: 22rem;

            .Content {
                height: 22rem;
            }
        }
    }
}