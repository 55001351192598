@import "./fonts/fonts.scss";

$theme1-text-medium: #333333;

//blueish theme
$theme1-primary1: #a7bbf8;
$theme1-primary2: #708cdf;
$theme1-primary3: #415bac;
$theme1-primary4: #253874;
$theme1-primary5: #242564;
$theme1-primary6: #0e0341;