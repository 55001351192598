.PlaneJourney {
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to bottom left, #f8d1d1, #66b5f4);
    overflow: hidden;
    position: relative;

    .PlaneWrapper {
        animation: fade-in 6s;
    }

    .Plane {
        display: inline-block;
        width: 150px;
        height: 50px;
        background-image: url('./images/plane.png');
        background-size: contain;
        background-repeat: no-repeat;
        position: absolute;
        top: 80%;
        left: 35%;
        transform: rotate(-3deg) translate(0, 0);
        z-index: 5;
        animation: aeroplane-motion 30s linear infinite;

        .NavigationLights {
            display: inline-block;
            position: absolute;
            box-sizing: border-box;
            width: 1px;
            height: 1px;
            border-radius: 50%;
            z-index: 15;
        }
        .WhiteAntiCollisionLights {
            visibility: hidden;
            display: inline-block;
            position: absolute;
            box-sizing: border-box;
            width: 1px;
            height: 1px;
            border-radius: 50%;
            z-index: 15;
            background-color: #feffd4;
            box-shadow: 0 0 2px 1px #fff;
        }
        .RedAntiCollisionLights {
            visibility: hidden;
            display: inline-block;
            position: absolute;
            box-sizing: border-box;
            width: 1px;
            height: 1px;
            z-index: 15;
            background-color: #d81111;
            box-shadow: 0 0px 2px 1px #d81111;
        }

        .GreenNavLight {
            bottom: 15px;
            left: 71px;
            background-color: #53c953;
            box-shadow: 0 0 1px 1px #74cf74;
        }

        .WingWhiteLight {
            bottom: 17px;
            left: 68px;
            animation: flash-wing-white-light 3s linear infinite;
        }
        .TailWhiteLight {
            bottom: 19px;
            left: 0;
            animation: flash-tail-white-light 3s linear infinite;
        }

        .TopRedLight {
            top: 25px;
            left: 95px;
            border-top-left-radius: 50%;
            border-top-right-radius: 50%;
            animation: flash-top-red-light 3s linear infinite;
        }
        .BottomRedLight {
            bottom: 8px;
            left: 77px;
            border-bottom-left-radius: 50%;
            border-bottom-right-radius: 50%;
            animation: flash-bottom-red-light 3s linear infinite;
        }

        @keyframes flash-wing-white-light {
            19% { visibility: hidden; }
            20% { visibility: visible; }
            27% { visibility: visible; }
            28% { visibility: hidden; }
            33% { visibility: hidden; }
            34% { visibility: visible; }
            41% { visibility: visible; }
            42% { visibility: hidden; }
        }
        @keyframes flash-tail-white-light {
            39% { visibility: hidden; }
            40% { visibility: visible; }
            47% { visibility: visible; }
            48% { visibility: hidden; }
            53% { visibility: hidden; }
            54% { visibility: visible; }
            61% { visibility: visible; }
            62% { visibility: hidden; }
        }
        @keyframes flash-top-red-light {
            29% { visibility: hidden; }
            30% { visibility: visible; }
            40% { visibility: visible; }
            41% { visibility: hidden; }
        }
        @keyframes flash-bottom-red-light {
            49% { visibility: hidden; }
            50% { visibility: visible; }
            60% { visibility: visible; }
            61% { visibility: hidden; }
        }

        @keyframes aeroplane-motion {
            0% { transform: rotate(-3deg) translate(0, 0); }
            18% { transform: rotate(-8deg) translate(19%, -27%); }
            20% { transform: rotate(-9deg) translate(20%, -30%); }
            22% { transform: rotate(-8.9deg) translate(19%, -33%); }
            28% { transform: rotate(-8.1deg) translate(12%, -55%); }
            30% { transform: rotate(-8deg) translate(10%, -60%); }
            38% { transform: rotate(-7.1deg) translate(3%, -87%); }
            40% { transform: rotate(-7deg) translate(0, -90%); }
            42% { transform: rotate(-6.8deg) translate(-3%, -87%); }
            44% { transform: rotate(-6.6deg) translate(-9%, -83%); }
            46% { transform: rotate(-6.4deg) translate(-18%, -77%); }
            48% { transform: rotate(-6.2deg) translate(-27%, -73%); }
            50% { transform: rotate(-6deg) translate(-38%, -70%); }
            59% { transform: rotate(-5.1deg) translate(-78%, -44%); }
            60% { transform: rotate(-5deg) translate(-80%, -40%); }
            61% { transform: rotate(-4.9deg) translate(-79%, -37%); }
            70% { transform: rotate(-3.8deg) translate(-60%, -3%); }
            79% { transform: rotate(-2.1deg) translate(-42%, 28%); }
            80% { transform: rotate(-2deg) translate(-40%, 30%); }
            90% { transform: rotate(-2.6deg) translate(-20%, 10%); }
        }
    }

    .Clouds {
        position: absolute;
        left: 0;
        width: 100%;
        height: 100%;
        display: inline-block;
        background-size: contain;
        background-repeat: no-repeat;
        animation: fade-in 2s;
    }
    
    .Cloud1 {
        background-image: url('./images/cloud1.png');
        background-position: bottom;
        z-index: 6;
        animation: clouds-move-left-1 16s linear infinite;
    }
    .Cloud2 {
        background-image: url('./images/cloud2.png');
        background-position: bottom;
        z-index: 4;
        animation: clouds-move-left-2 18s linear infinite;
    }
    .Cloud3 {
        background-image: url('./images/cloud3.png');
        z-index: 6;
        animation: clouds-move-left-3 19.5s linear infinite;
    }
    .Cloud4 {
        background-image: url('./images/cloud4.png');
        background-position: bottom;
        z-index: 4;
        animation: clouds-move-left-4 21s linear infinite;
    }
    .Cloud5 {
        background-image: url('./images/cloud5.png');
        z-index: 6;
        animation: clouds-move-left-5 22.5s linear infinite;
    }

    @keyframes clouds-move-left-1 {
        from { transform: translateX(100%); }
        to { transform: translateX(-150%); }
    }
    @keyframes clouds-move-left-2 {
        from { transform: translateX(150%); }
        to { transform: translateX(-100%); }
    }
    @keyframes clouds-move-left-3 {
        from { transform: translateX(200%); }
        to { transform: translateX(-100%); }
    }
    @keyframes clouds-move-left-4 {
        from { transform: translateX(250%); }
        to { transform: translateX(-100%); }
    }
    @keyframes clouds-move-left-5 {
        from { transform: translateX(300%); }
        to { transform: translateX(-100%); }
    }

    @keyframes fade-in {
        from { opacity: 0 };
        to { opacity: 1 };
    }
}

@media (min-width: 320px) {
    .PlaneJourney {
        .Plane {
            width: 181px;
            height: 60px;
            top: 75%;
            left: 35%;
    
            .GreenNavLight {
                bottom: 20px;
                left: 87px;
            }
    
            .WingWhiteLight {
                bottom: 21px;
                left: 80px;
            }
            .TailWhiteLight {
                bottom: 22px;
            }
    
            .TopRedLight {
                top: 31px;
                left: 115px;
            }
            .BottomRedLight {
                bottom: 9px;
                left: 93px;
            }
        }

    }
}

@media (min-width: 480px) {
    .PlaneJourney {
        .Plane {
            width: 241px;
            height: 80px;
            top: 70%;
            left: 35%;
    
            .GreenNavLight {
                bottom: 27px;
                left: 114px;
            }
    
            .WingWhiteLight {
                bottom: 27px;
                left: 106px;
            }
            .TailWhiteLight {
                bottom: 30px;
            }
    
            .TopRedLight {
                top: 41px;
                left: 155px;
            }
            .BottomRedLight {
                bottom: 12px;
                left: 122px;
            }
        }

    }
}

@media (min-width: 640px) {
    .PlaneJourney {
        .Plane {
            width: 301px;
            height: 100px;
            top: 65%;
            left: 35%;
    
            .GreenNavLight {
                bottom: 34px;
                left: 143px;
            }
    
            .WingWhiteLight {
                bottom: 35px;
                left: 133px;
            }
            .TailWhiteLight {
                bottom: 38px;
            }
    
            .TopRedLight {
                top: 52px;
                left: 195px;
            }
            .BottomRedLight {
                bottom: 15px;
                left: 153px;
            }
        }

    }
}

@media (min-width: 800px) {
    .PlaneJourney {
        .Plane {
            width: 361px;
            height: 120px;
            top: 55%;
            left: 35%;
    
            .NavigationLights {
                width: 2px;
                height: 2px;
            }
            .WhiteAntiCollisionLights {
                width: 2px;
                height: 2px;
                box-shadow: 0 0 3px 2px #fff;
            }
            .RedAntiCollisionLights {
                width: 2px;
                height: 2px;
                box-shadow: 0 0px 2px 1px #d81111;
            }
    
            .GreenNavLight {
                bottom: 41px;
                left: 172px;
                box-shadow: 0 0 3px 2px #74cf74;
            }
    
            .WingWhiteLight {
                bottom: 42px;
                left: 160px;
            }
            .TailWhiteLight {
                bottom: 46px;
            }
    
            .TopRedLight {
                top: 62px;
                left: 234px;
            }
            .BottomRedLight {
                bottom: 18px;
                left: 184px;
            }
        }

    }
}

@media (min-width: 960px) {
    .PlaneJourney {
        .Plane {
            width: 512px;
            height: 170px;
            top: 40%;
            left: 35%;
    
            .NavigationLights {
                width: 3px;
                height: 3px;
            }
            .WhiteAntiCollisionLights {
                width: 3px;
                height: 3px;
                box-shadow: 0 0 7px 5px #fff;
            }
            .RedAntiCollisionLights {
                width: 3px;
                height: 3px;
                box-shadow: 0 0px 4px 2px #d81111;
            }
    
            .GreenNavLight {
                bottom: 56px;
                left: 248px;
                box-shadow: 0 0 4px 3px #74cf74;
            }
    
            .WingWhiteLight {
                bottom: 58px;
                left: 226px;
            }
            .TailWhiteLight {
                bottom: 62px;
                left: 0;
            }
    
            .TopRedLight {
                top: 87px;
                left: 325px;
            }
            .BottomRedLight {
                bottom: 23px;
                left: 273px;
            }
        }

    }
}