@import "../../styles/main";

.Footer {
    background-color: $theme1-primary5;
    color: #fafafa;
    text-align: center;
    flex-shrink: 0;

    .DesktopOnly {
        display: none;
    }
    .MobileOnly {
        display: block;
    }
    
    .Content {
        padding: 0.6rem 0;
        position: relative;
        margin: 0 auto;
        max-width: 1200px;

        .Text {
            p {
                font-size: 1rem;
            }
        }

        .Links {
            padding: 0.5rem 0 0.75rem;
            font-size: 3rem;
    
            .Icon {
                height: 100%;
                color: #fafafa;
                margin: 0 1rem;
            }
        }
    }
}

@media (min-width: 720px) {
    .Footer {

        .DesktopOnly {
            display: block;
        }
        .MobileOnly {
            display: none;
        }

        .Content {
    
            .Links {
                position: absolute;
                top: 0.35rem;
                right: 0;
                left: unset;
                padding: 0;
                font-size: 2rem;
        
                .Icon {
                    margin: unset;
                    margin-right: 0.5rem;
                }
            }
        }
    }
}