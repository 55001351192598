@import "../../../../styles/main";

.Button {
    display: inline-block;
    box-sizing: border-box;
    margin: 0 0.25rem;
    padding: 0.4rem 0.6rem;
    border-radius: 1rem;
    background-color: $theme1-primary3;
    color: #fff;
    text-decoration: none;
    cursor: pointer;
    transition: all 0.4s ease-in-out;

    &:first-child {
        margin-left: 0;
    }
    &:last-child {
        margin-right: 0;
    }

    .Text {
        padding: 0 0.25rem;
    }

    .FaIcon {
        padding-left: 0.25rem;
    }

    &:hover {
        background-color: $theme1-primary4;
    }
}

.Blue {
    background-color: #2172eb;

    &:hover {
        background-color: #225baf;
    }
}