.PageContent {
    max-width: 1200px;
    margin: 0 auto;
    box-sizing: border-box;
    padding: 1rem;
}

@media (min-width: 640px) {
    .PageContent {
        padding: 1rem;
    }
}