@import "../../../../styles/main";

.Circle {
    display: inline-block;
    height: 2.5rem;
    width: 2.5rem;
    box-sizing: border-box;
    border-radius: 50%;
    background-color: #fff;
    box-shadow: 1px 1px 3px 1px #ccc;
    cursor: pointer;

    .Icon {
        display: inline-block;
        border-radius: 50%;
        height: 100%;
        width: 100%;
        background-size: contain;
    }
}

.FaIcon {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 3.5rem;
    width: 3.5rem;
    box-sizing: border-box;
    border-radius: 50%;
    background-color: $theme1-primary2;
    color: #fff;
    text-align: center;
    font-size: 1.5rem;
    cursor: pointer;
    box-shadow: 1px 1px 3px 1px #777;
    transition: all .2s ease-in-out;

    &:hover {
        transform: scale(1.1);
    }
}

.Blue {
    background-color: #2172eb;
}

.Active {
    background-color: $theme1-primary4;
    transform: scale(1.1);
    box-shadow: 0 0 5px 4px #bbb;
}