@import "../../../../styles/main";

$blue: #2a60f3;

.CircleWithBorder {
    display: inline-block;
    height: 100%;
    width: 100%;
    border: 1px solid $theme1-primary3;
    border-radius: 50%;
    background-color: #fff;
    box-sizing: border-box;

    &.GreenBorder {
        border-color: #0bb18d;
    }

    &.BlueBorder {
        border-color: $blue;
    }

    &.ThinBorder {
        border-width: 1px;
    }

    &.MediumBorder {
        border-width: 2px;
    }

    &.ThickBorder {
        border-width: 3px;
    }

    &.WhiteFill {
        background-color: #fff;
    }

    &.BlueFill {
        background-color: $blue;
    }

    &.GreenFill {
        background-color: $theme1-primary4;
    }
}