.Canvas {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    
    &.StaticCanvas {
        z-index: 4;
    }
    &.AnimationCanvas {
        z-index: 5;
    }
}

.PreRenderedCanvases {
    display: none;
}