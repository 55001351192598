@import "../../../styles/main";

$banner-height: 25rem;
$title-font-size: 3rem;

.Banner {
    position: relative;
    width: 100%;
    height: $banner-height;
    display: flex;
    align-items: center;

    &.Default {
        background-image: linear-gradient(to bottom right, $theme1-primary4, $theme1-primary1);
    }
    
    .Title {
        box-sizing: border-box;
        padding: 1rem;
        color: #fff;
        text-align: center;
        font-size: 2.5rem;
        font-weight: 300;
        line-height: 3.5rem;
        letter-spacing: 0.1rem;
        position: absolute;
        width: 100%;
        z-index: 10;

        .TitleShadow {
            text-shadow: 0px 0px 10px #333;
        }

        // .TitleBackground {
        //     position: relative;

        //     &:after {
        //         content: "";
        //         display: inline-block;
        //         position: absolute;
        //         top: -5%;
        //         left: -10%;
        //         height: 115%;
        //         width: 120%;
        //         background-color: rgba(0, 0, 0, 0.1);
        //         z-index: -1;
        //     }
        // }
        
    }
}

@media (min-width: 480px) {
    .Banner {

        .Title {
            font-size: $title-font-size;
            line-height: 4rem;
        }
    }
}