$stars-canvas-height: 1000;
$stars-canvas-width: 2500;

@function generate-box-shadows($numShadows) {
    $box-shadow-value: '#{random($stars-canvas-width)}px #{random($stars-canvas-height)}px #FFF';
    
    @for $_ from 2 through $numShadows {
        $box-shadow-value: '#{$box-shadow-value} , #{random($stars-canvas-width)}px #{random($stars-canvas-height)}px #FFF';
    }

    @return unquote($box-shadow-value);
}

$shadows-small: generate-box-shadows(600);
$shadows-medium: generate-box-shadows(300);
$shadows-large: generate-box-shadows(100);

.NightSky {
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to bottom right, #21044f, #6db9b3);
    overflow: hidden;

    .StarsSmall {
        width: 1px;
        height: 1px;
        background: transparent;
        box-shadow: $shadows-small;
        border-radius: 100%;
        animation: move-stars-up 400s linear infinite;
    }
    .StarsSmall:after {
        content: "";
        position: absolute;
        top: $stars-canvas-height + px;
        width: 1px;
        height: 1px;
        background: transparent;
        box-shadow: $shadows-small;
        border-radius: 100%;
    }

    .StarsMedium{
        width: 2px;
        height: 2px;
        background: transparent;
        box-shadow: $shadows-medium;
        border-radius: 100%;
        animation: move-stars-up 200s linear infinite;
    }
    .StarsMedium:after {
        content: "";
        position: absolute;
        top: $stars-canvas-height + px;
        width: 2px;
        height: 2px;
        background: transparent;
        box-shadow: $shadows-medium;
        border-radius: 100%;
    }

    .StarsLarge {
        width: 3px;
        height: 3px;
        background: transparent;
        box-shadow: $shadows-large;
        border-radius: 100%;
        animation: move-stars-up 100s linear infinite;
    }
    .StarsLarge:after {
        content: "";
        position: absolute;
        top: $stars-canvas-height + px;
        width: 3px;
        height: 3px;
        background: transparent;
        box-shadow: $shadows-large;
        border-radius: 100%;
    }

    @keyframes move-stars-up {
        from { transform: translateY(0px); }
        to { transform: translateY(-1000px); }
    }
}