$banner-height: 25rem;

.CherryBlossoms {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;

    .FaintTrees {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url('./images/forest-pattern-faint.jpg');
        opacity: 0.75;
        z-index: 1;
        background-position: center;
        background-size: contain;
        animation: fade-in-background-trees 6s;
    }

    .Trees {
        position: relative;
        width: 100%;
        height: 100%;
        background-image: url('./images/forest-pattern.png');
        background-size: cover;
        opacity: 1;
        z-index: 2;
        background-position: center;
        animation: fade-in-foreground-trees 2s;
    }

    .Tint {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(to bottom right, #a3478f, #c43a51);
        z-index: 3;
        opacity: 0.3;
    }

    .Canvas {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 5;
        animation: fade-in-canvas 3s;
    }
}

@keyframes fade-in-background-trees {
    from { opacity: 0 };
    to { opacity: 0.75 };
}
@keyframes fade-in-foreground-trees {
    from { opacity: 0 };
    to { opacity: 1 };
}
@keyframes fade-in-canvas {
    from { opacity: 0 };
    to { opacity: 1 };
}

@media (min-width: 640px) {
    .CherryBlossoms {
        .Trees {
            background-size: auto;
        }
    }
    
}