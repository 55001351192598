@import "../../../styles/main";

.WorkExp {
    display: flex;

    .TimePeriod.DesktopOnly {
        display: none;
    }

    .TimelineGraphics {
        margin-left: -0.5rem;
        position: relative;
        flex-basis: 10%;
        text-align: center;

        .CheckpointCircle {
            display: inline-block;
            height: 1.25rem;
            width: 1.25rem;
            z-index: 5;
        }
    }

    .MainInfo {
        margin-top: -0.1rem;
        flex-basis: 90%;
        padding-bottom: 1.5rem;

        .TimePeriod.MobileOnly {
            margin: 0.1rem 0 0.75rem;
            font-size: 1.3rem;
            font-weight: 800;
        }

        .Heading {
            margin-bottom: 1rem;

            .Title {
                margin-bottom: 0.75rem;
                font-size: 1.3rem;
                font-weight: 600;
                line-height: 1.7rem;
            }
            .Subtitle {
                margin: 0.2rem 0 0.5rem;
                font-size: 1.3rem;
                font-weight: 600;
                font-style: italic;
            }
        }
    
        .Summary {
            
        }
    
        .TechUsed {
    
        }
    
        .ExpandableSection {
            margin: 0.75rem 0;
    
            .SectionHeading {
                margin: 0.25rem 0;
    
                .ExpandButton {
        
                }
                .CollapseButton {
        
                }
        
                .SectionName {
                    font-size: 1.3rem;
                    font-weight: 500;
                }
            }
    
        }
    }
}

.SlideInFromLeft {
    animation: slide-in-from-left 1s;
}
.SlideInFromRight {
    animation: slide-in-from-right 1s;
}
.FadeIn {
    animation: fade-in 1s;
}

@keyframes slide-in-from-left {
    from { transform: translateX(-100%); }
    to { transform: translateX(0); }
}
@keyframes slide-in-from-right {
    from { transform: translateX(100%); }
    to { transform: translateX(0); }
}
@keyframes fade-in {
    from { opacity: 0; }
    to { opacity: 1; }
}

@media (min-width: 640px) {
    .WorkExp {
    
        .TimePeriod.DesktopOnly {
            margin-top: -0.25rem;
            display: inline-block;
            flex-basis: 10%;
            font-size: 1.2rem;
            font-weight: 500;
            text-align: right;
            line-height: 1.6rem;
            word-break: break-word;
            hyphens: auto;
        }

        .TimePeriod.MobileOnly {
            display: none;
        }
    
        .TimelineGraphics {
            margin-left: 0;
            flex-basis: 6%;
    
            .CheckpointCircle {
                display: inline-block;
            }
        }
    
        .MainInfo {
            margin-top: -0.2rem;
            flex-basis: 84%;

            .Heading {
                .Title {
                    margin-bottom: 0.5rem;
                    font-size: 1.5rem;
                }
                .Subtitle {
                    font-size: 1.4rem;
                }
            }
        }
    
    }
}

@media (min-width: 960px) {
    .WorkExp {
    
        .TimePeriod.DesktopOnly {
            margin-top: -0.1rem;
            flex-basis: 20%;
            font-size: 1.3rem;
        }
    
        .TimelineGraphics {
            flex-basis: 10%;
    
            .CheckpointCircle {
                height: 1.5rem;
                width: 1.5rem;
            }
        }
    
        .MainInfo {
            margin-top: -0.1rem;
            flex-basis: 70%;
        }
    }
}