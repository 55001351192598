.ProjectList {
    margin: 1rem 0;
    display: flex;
    flex-direction: column;
 
    .ProjectListItem {
        width: 100%;
        margin: 1rem 0;
    }
}

@media (min-width: 880px) {
    .ProjectList {
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;

        .ProjectListItem {
            width: 49%;
        }
    }
}
