@import "../../../styles/main";

$toolbar-height: 3.5rem;

.Toolbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: $toolbar-height;
    padding: 0 20px;
    box-sizing: border-box;
    z-index: 90;

    &.Transition10 {
        background-color: rgba(255, 255, 255, 0.10);
        box-shadow: 0 0px 5px 0 rgba(102, 102, 102, 0.10);
    }
    &.Transition20 {
        background-color: rgba(255, 255, 255, 0.20);
        box-shadow: 0 0px 5px 0 rgba(102, 102, 102, 0.20);
    }
    &.Transition30 {
        background-color: rgba(255, 255, 255, 0.30);
        box-shadow: 0 0px 5px 0 rgba(102, 102, 102, 0.30);
    }
    &.Transition40 {
        background-color: rgba(255, 255, 255, 0.40);
        box-shadow: 0 0px 5px 0 rgba(102, 102, 102, 0.40);
    }
    &.Transition50 {
        background-color: rgba(255, 255, 255, 0.50);
        box-shadow: 0 0px 5px 0 rgba(102, 102, 102, 0.50);
    }
    &.Transition60 {
        background-color: rgba(255, 255, 255, 0.60);
        box-shadow: 0 0px 5px 0 rgba(102, 102, 102, 0.60);
    }
    &.Transition70 {
        background-color: rgba(255, 255, 255, 0.70);
        box-shadow: 0 0px 5px 0 rgba(102, 102, 102, 0.70);
    }
    &.Transition80 {
        background-color: rgba(255, 255, 255, 0.80);
        box-shadow: 0 0px 5px 0 rgba(102, 102, 102, 0.80);
    }
    &.Transition90 {
        background-color: rgba(255, 255, 255, 0.90);
        box-shadow: 0 0px 5px 0 rgba(102, 102, 102, 0.90);
    }
    &.Solid {
        background-color: #fff;
        box-shadow: 0 0px 5px 0 #666;
    }

    .Content {
        display: flex;
        margin: 0 auto;
        max-width: 1200px;
        height: 100%;

        .Section1 {
            flex: 1 1 10%;
            display: inline-flex;
            align-items: center;
        }

        .Section2 {
            flex: 5 1 50%;
        }

        .Section3 {
            flex: 4 1 40%;
        }

        .Logo {
            height: 80%;
        }
    
        nav {
            height: 100%;
        }
    }
}

@media (max-width: 719px) {
    .DesktopOnly {
        display: none;
    }
}