@import "../../../../styles/main";

.DrawerToggle {
    display: flex;
    flex-flow: column;
    justify-content: space-around;
    align-items: center;
    margin-left: auto;
    margin-right: 0;
    padding: 10px 0;
    box-sizing: border-box;
    width: 36px;
    height: 100%;
    font-size: 2.8rem;
    color: $theme1-primary4;
    cursor: pointer;

    &.HomePageTheme,
    &.SkillsPageTheme {
        color: #fff;
    }

    &.JourneyPageTheme {
        color: #424242;
    }

    &.ProjectsPageTheme {
        color: #fff;
    }
}

@media all and (min-width: 720px) {
    .DrawerToggle {
        display: none;
    }
}