$open-sans: 'Open Sans';

@font-face {
    font-family: $open-sans;
    src: url(OpenSans-Light.ttf);
    font-weight: 100;
}
@font-face {
    font-family: $open-sans;
    src: url(OpenSans-LightItalic.ttf);
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: $open-sans;
    src: url(OpenSans-Regular.ttf);
    font-weight: normal;
}
@font-face {
    font-family: $open-sans;
    src: url(OpenSans-Italic.ttf);
    font-weight: normal;
    font-style: italic;
}
@font-face {
    font-family: $open-sans;
    src: url(OpenSans-Regular.ttf);
    font-weight: 300;
}
@font-face {
    font-family: $open-sans;
    src: url(OpenSans-Italic.ttf);
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: $open-sans;
    src: url(OpenSans-SemiBold.ttf);
    font-weight: 500;
}
@font-face {
    font-family: $open-sans;
    src: url(OpenSans-SemiBoldItalic.ttf);
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: $open-sans;
    src: url(OpenSans-Bold.ttf);
    font-weight: bold;
}
@font-face {
    font-family: $open-sans;
    src: url(OpenSans-BoldItalic.ttf);
    font-weight: bold;
    font-style: italic;
}
@font-face {
    font-family: $open-sans;
    src: url(OpenSans-Bold.ttf);
    font-weight: 700;
}
@font-face {
    font-family: $open-sans;
    src: url(OpenSans-BoldItalic.ttf);
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: $open-sans;
    src: url(OpenSans-ExtraBold.ttf);
    font-weight: 900;
}
@font-face {
    font-family: $open-sans;
    src: url(OpenSans-ExtraBoldItalic.ttf);
    font-weight: 900;
    font-style: italic;
}
