@import "../../../styles/main";

$banner-height: 40rem;
$title-font-size: 3.25rem;

.Banner {
    display: flex;
    position: relative;
    width: 100%;
    height: $banner-height;
    align-items: center;
    justify-content: center;

    &.Default {
        background-image: linear-gradient(to bottom right, $theme1-primary4, $theme1-primary1);
    }
    
    .Title {
        display: inline-block;
        margin: 0 auto;
        color: #fff;
        text-align: center;
        font-size: 2.75rem;
        letter-spacing: 0.2rem;
        position: absolute;
        width: 100%;
        top: ($banner-height - $title-font-size)/2;
        z-index: 10;

        .TitleShadow {
            text-shadow: 0px 0px 10px #555;
        }
    }
}

@media (min-width: 480px) {
    .Banner {

        .Title {
            font-size: $title-font-size;
        }
    }
}