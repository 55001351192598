.Accordion {
    margin: 0.75rem 0;

    .HeadingRow {
        display: inline-block;
        margin: 0.25rem 0;
        cursor: pointer;

        .ExpandButton {
            display: inline-block;
            margin-right: 0.4rem;
        }

        .IconOpening {
            animation: animate-icon-opening 0.1s linear;
        }
        .IconClosing {
            animation: animate-icon-closing 0.1s linear;
        }

        .Heading {
            font-size: 1.2rem;
            font-weight: 500;
        }

        @keyframes animate-icon-opening {
            from { transform: rotate(-180deg); }
            to { transform: rotate(0deg); }
        }
        @keyframes animate-icon-closing {
            from { transform: rotate(180deg); }
            to { transform: rotate(0deg); }
        }
        
    }

    .Content {
        margin-left: 0.1rem;
        overflow: hidden;

        &.Open {
            display: block;
            height: 100%;
        }
        &.Closed {
            display: none;
            height: 0;
        }

        &.ContentOpening {
            animation: animate-content-opening 0.1s linear;
        }
        &.ContentClosing {
            animation: animate-content-closing 0.1s linear;
        }

        @keyframes animate-content-opening {
            from { max-height: 0px; }
            to { max-height: 300px; }
        }
        @keyframes animate-content-closing {
            from { max-height: 300px; }
            to { max-height: 0px; }
        }

        > ul {
            margin-top: 0.5rem;
            padding-left: 1.5em;
            list-style-type: disc;
            line-height: 1.6em;

            > li {
                font-size: 1.1em;
            }
        }

        p {
            margin: 0.8rem 0;
            font-size: 1.1rem;
            line-height: 1.6rem;
            text-align: justify;
        }
    }

}

@media (min-width: 640px) {
    .Accordion {

        .HeadingRow {
            .Heading {
                font-size: 1.3rem;
            }
        }

        .Content {
            p {
                text-align: left;
                line-height: 1.5rem;
            }
        }
    }
}