@import "../../../../styles/main";

$blue: #2a60f3;

.Line {
    display: inline-block;
    position: absolute;
    border-left: 2px solid #000;
    border-color: $theme1-primary3;
    height: 100%;
    left: 50%;
    margin-left: -1px;
    top: 1px;
    z-index: -1;

    &.Green {
        border-color: #0bb18d;
    }
    &.Blue {
        border-color: $blue;
    }
    &.Black {
        border-color: #000;
    }

    &.Solid {
        border-style: solid;
    }
    &.Dotted {
        border-style: dotted;
    }
    &.Dashed {
        border-style: dashed;
    }
}