.Tag {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    margin: 0.3rem 0.6rem 0.3rem 0;
    padding: 0.25rem;
    min-height: 2.5rem;
    border: 1px solid #ccc;
    box-shadow: 1px 1px 3px 1px #ccc;
    background-color: #fff;

    &:first-child {
        margin-left: 0;
    }
    &:last-child {
        margin-right: 0;
    }

    .Icon {
        display: inline-block;
        box-sizing: border-box;
        height: 2rem;

        img {
            height: 2rem;
            max-width: 2rem;
        }
    }

    .Text {
        display: inline-block;
        box-sizing: border-box;
        padding: 0.25rem 0.5rem;

        height: 2rem;
        line-height: 1.4rem;
    }
}

.Tag.Small {
    min-height: 2rem;
    margin: 0.2rem 0.35rem 0.2rem 0;

    &:first-child {
        margin-left: 0;
    }
    &:last-child {
        margin-right: 0;
    }

    .Icon {
        height: 1.5rem;

        img {
            height: 1.5rem;
            max-width: 1.5rem;
        }
    }

    .Text {
        height: 1.5rem;
        font-size: 0.9rem;
        padding: 0 0.5rem;
    }
}