.AnimationCanvas {
    position: absolute;
    display: inline-block;
    width: 12rem;
    height: 23.5rem;
    bottom: 1.5rem;
    margin: 0 auto;
    left: 0;
    right: 0;
}

.PreRenderedCanvases {
    display: none;
}

@media (min-width: 320px) {
    .AnimationCanvas {
        width: 14rem;
        height: 23rem;
        bottom: 2rem;
    }
}

@media (min-width: 480px) {
    .AnimationCanvas {
        width: 20rem;
        height: 22rem;
        bottom: 3rem;
    }
}

@media (min-width: 640px) {
    .AnimationCanvas {
        width: 24rem;
    }
}